@import "../../styles/common";

.output-translation-text {
  .text-time {
    color: $sub-text-color;
    margin-right: 0.7rem;
    font-size: 0.8rem;
  }
  &.ui {
    padding: 0;
    font-size: 1.1rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-stop {
    color: $color-icon-stop;
  }

  .icon-play {
    color: $color-icon-play;
    &:disabled {
      color: $color-icon-disabled;
    }
  }

  &.speech {
    display: grid;
    grid-template-areas: "message-data message-bubble";
    grid-template-columns: 7rem auto;

    .message-data {
      grid-area: message-data;
      align-self: center;
      font-size: 10px;
      text-align: center;
    }

    .message-bubble {
      grid-area: message-bubble;
      // border: 2px solid darken(#e7e7e7, 12%);
      background-color: $text-background;
      color: $text-color;
      padding: 2.2rem 1.1rem;
      margin-top: 1.9rem;
      border-radius: 1rem;
      display: grid;
      align-items: center;
      font-size: 1.4rem;
      width: 100%;
      grid-template-areas: "time message controls";
      grid-template-columns: 30px auto 50px;

      &.active {
        border: 2px solid rgb(218, 24, 24);
      }

      .message {
        grid-area: message;
      }

      .controls {
        grid-area: controls;
      }

      &:first-child {
        margin-right: 1.1rem;
      }
      &:last-child {
        margin-right: 1.1rem;
      }
      &.translated {
        // background-color: darken($text-background, 7%);
      }
      // &:last-child {
      //   background-color: darken($text-background, 7%);
      // }

      .separation-line {
        color: $fancy-color;
      }
    }
  }
}
