@import "../_common";

.home-page {
  overflow: auto;
}

.main-root {
  display: grid;
  height: "100%";
  background-color: black;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: 1fr;
  text-align: center;
  height: 100vh;
}

.logo {
  grid-row: 1/2;
}

.content {
  grid-row: 2/6;
  width: 100%;
  div {
    width: 100%;
  }
}

.product-background {
  .product-presentation {
    &-container {
      display: flex;
    }
    &-spacer {
      background-color: black;
      width: 600px;
      margin-left: -600px;
      min-height: 300px;
    }
    &-bg {
      background-image: url("../../../public/images/home/Product_Presentation.svg");
      background-size: contain;
      background-position: 0;
      min-height: 300px;
      background-repeat: no-repeat;
      margin-left: -10px;
    }
  }

  .product-button {
    padding: 3rem;
    width: 30rem;
  }
}
