@import "../../styles/common";

.code-container {
  background-color: rgba(0, 0, 0, 0.803);
  color: white;
  padding: 1rem;
  margin-top: 1rem;
  position: relative;
  max-width: 80%;
  overflow-wrap: break-word;

  .btn-copy {
    margin-right: 1rem;
    position: absolute;
    top: 3;
    right: 0;
    display: none;
  }

  &:hover {
    .btn-copy {
      display: block;
    }
  }

  // themes
  &.link-small {
    padding: 0;
    margin-top: 0;
    background-color: inherit;
    color: $link-color;

    .btn-copy {
      margin-right: 0.8rem;
    }
  }
}
