$play-action: #34d120;
$stop-action: #ff0000;
$question-action: rgb(0, 8, 255);
$btn-background-color: #fff;
$btn-background-color-active: #ff8c00;

.left-menu {
  button:hover {
    transform: scale(1.05);
  }
}

.start-button {
  color: $play-action;
  background-color: $btn-background-color;
  border-radius: 30px;
  width: 45px;
  height: 45px;
  margin-left: auto !important;
  margin-right: auto !important;
  svg {
    color: $play-action;
    position: relative;
    right: 5.7px;
  }
}

.start-button-ref {
  color: $play-action;
  background-color: $btn-background-color;
  border-radius: 30px;
  margin-left: auto !important;
  margin-right: auto !important;
  svg {
    color: $play-action;
    position: relative;
    right: 5.7px;
  }
}

.stop-button {
  color: $stop-action;
  background-color: $btn-background-color-active;
  width: 47px;
  margin-left: auto !important;
  margin-right: auto !important;
  svg {
    position: relative;
    right: 5.7px;
  }
}

.question-button {
  color: $play-action;
  background-color: $btn-background-color;
  border-radius: 30px;
  width: 45px;
  height: 45px;
  margin-left: auto !important;
  margin-right: auto !important;
  svg {
    color: $question-action;
    position: relative;
    right: 5.7px;
  }
}

.logo-img {
  width: 47px;
  margin: auto;
}
