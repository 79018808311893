$color-p: #666;
$color-bg: rgb(197, 224, 21);
$content-padding-left: 6rem;

.box-option {
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 3rem;

  &-title {
    font: 16px Arial, sans-serif;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  &-dsc {
    padding-left: $content-padding-left;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &-image {
    font: 16px Arial, sans-serif;
    margin-bottom: 2rem;
    margin: 0 auto;
    display: block;

    &.no-border {
      border: 0;
      box-shadow: none !important;
    }

    &.img-100 {
      width: 100%;
    }

    &.img-75 {
      width: 60%;
    }

    &.img-50 {
      width: 50%;
    }

    &.img-25 {
      width: 25%;
    }
  }
}
