@import "../../styles/vars";

// $height-top-first-row: 100px;
// $height-top: $height-top-video + $height-top-first-row;
@import "../../styles/common";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic,wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:regular,bold,italic,wght@300;400&display=swap");

@import "../../styles/buttons.scss";

html,
body {
  height: 100%;
}

.recorder-main {
  display: grid;
  grid-template-areas: "left top" "left chat" "left chat" "left chat";
  grid-template-rows: 100px; // only first row
  grid-template-columns: 50px; // only the first column
  height: 100vh;
  margin-bottom: 2rem;
  overflow-y: hidden;
  background-color: rgb(214, 216, 219);

  .block-left-bar {
    grid-area: left;
  }

  .block-top-bar {
    grid-area: top;
  }

  .block-text-container {
    grid-area: chat;
    padding-bottom: 5rem;
    overflow-y: auto;
    padding-right: 5rem;
  }
}

:root {
  font-size: 62.5%;
  body {
    position: relative;
    color: $text-color;
    height: 100vh;
    // background: linear-gradient(
    //   200.96deg,
    //   #fedc2a -29.09%,
    //   #dd5789 51.77%,
    //   #7a2c9e 129.35%
    // );
    background: white;
    // font-family: sans-serif;
    font-family: "Open Sans", "Nunito Sans", sans-serif;
    font-weight: 400;

    // Body font size 16px
    font-size: 1.6rem;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }
}
