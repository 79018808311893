@import "../../styles/common";

.audio-messages-container {
  position: relative;
}

.toast-scroll-message {
  position: fixed;
  top: 0;
  right: 0;
}

.toast-text {
  font-size: 1.3rem;
}

.ui-actions {
  background-color: $fancy-color;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  text-align: center;
  font-size: 1rem;
  margin: 0 auto;
  padding: 0.5rem;
}
