.setup-meeting-page {
  &-container {
    min-width: 500px;
  }

  &-input {
    height: 5rem;
    font-size: 2rem;

    &::placeholder {
      font-size: 1.3rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 10rem;
    gap: 3rem;
  }
}
