@import "styles/common";
@import "styles/buttons.scss";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic,wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:regular,bold,italic,wght@300;400&display=swap");
// @import url('https://fonts.googleapis.com/css?family=Karla:regular,bold,italic,wght@300;400&display=swap');

html,
body {
  height: 100%;
}

.base-app {
  display: grid;
  grid-template-areas: "top" "content";
  grid-template-rows: $top-bar-height; // only first row
  height: 100%;

  .base-app-top {
    grid-area: top;
  }

  .base-app-content {
    grid-area: content;
    padding-bottom: 5rem;
  }
}

:root {
  font-size: 62.5%;
  body {
    position: relative;
    color: $text-color;
    // background: linear-gradient(
    //   200.96deg,
    //   #fedc2a -29.09%,
    //   #dd5789 51.77%,
    //   #7a2c9e 129.35%
    // );
    background: white;
    // font-family: sans-serif;
    font-family: "Open Sans", "Nunito Sans", sans-serif;
    font-weight: 400;

    // Body font size 16px
    font-size: 1.6rem;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }
}
