$primary: #18a0fb;
$info: #0063a6;
$body-bg: #f2f2f2;
$body-color: #111;
$fancy-color: rgba(102, 51, 153, 0.287);

$link-color: $primary;

$text-color: #4e4e4e;
$sub-text-color: #e7e7e7;
$text-background: #f4f7fb;
$btn-background-color: #f3f3f3;

$color-icon-stop: red;
$color-icon-play: #34d120;
$color-icon-disabled: gray;
$btn-primary-color: yellow;

$navbar-light-color: $fancy-color;

// sizes
$top-bar-height: 100px;

@import "~bootstrap/scss/bootstrap";

.btn-primary {
  color: $white;
}

.nav-light {
  .nav-link {
    color: $primary;
  }
}
