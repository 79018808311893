$color-p: #666;
$color-bg: rgb(197, 224, 21);
$content-padding-left: 6rem;

.tutorial-item {
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 3rem;

  &-number {
    border-radius: 50%;
    width: 36px;
    height: 36px;

    background: $color-bg;
    border: 2px solid $color-p;
    color: $color-p;
    text-align: center;

    font: 16px;
    margin-right: 2rem;
    font-family: Arial, sans-serif;

    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;

    &.tiny {
      width: 18px;
      height: 18px;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &-title {
    font: 16px Arial, sans-serif;
    margin-bottom: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &-dsc {
    padding-left: $content-padding-left;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &-image {
    font: 16px Arial, sans-serif;
    margin-bottom: 2rem;
    margin: 0 auto;
    display: block;

    &.no-border {
      border: 0;
      box-shadow: none !important;
    }

    &.img-100 {
      width: 100%;
    }

    &.img-75 {
      width: 60%;
    }

    &.img-50 {
      width: 50%;
    }

    &.img-25 {
      width: 25%;
    }
  }

  &-code {
    background-color: black;
    color: white;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  &-symbol {
    font-size: 2rem;
  }
}
