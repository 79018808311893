@import "../../styles/vars";

// if you update $height-top-video, you need to also update it here:
//   src/components/MediaPlayer/MediaPlayer.tsx
$height-top-video: 200px;
$height-top-first-row: 100px;
$height-top: $height-top-video + $height-top-first-row;

$gap: 60px;

.transcript-page {
  height: 100%;

  .container {
    display: grid;
    grid-template-areas: "top" "messages";
    grid-template-rows: $height-top; // only first row
    max-height: 100%;

    .grid-top {
      grid-area: top;
    }

    .grid-messages {
      grid-area: messages;
      overflow-y: auto;
      max-height: calc(100vh - $top-bar-height - $height-top - $gap);
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .grid-top {
    display: grid;
    grid-template-areas:
      "grid-top-state grid-top-form  grid-top-buttons"
      "grid-top-video grid-top-video grid-top-video";
    grid-template-columns: 2fr 4fr 1fr; // only first column
    grid-template-rows: $height-top-first-row $height-top-video;
  }

  .grid-top-state {
    grid-area: grid-top-state;
    font-size: 1.2rem;
  }

  .grid-top-form {
    grid-area: grid-top-form;
    font-size: 1.2rem;
  }

  .grid-top-buttons {
    grid-area: grid-top-buttons;
    text-align: right;
  }

  .grid-top-video {
    grid-area: grid-top-video;
    text-align: center;
  }
}
